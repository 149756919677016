import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import classNames from 'classnames'
import { InView } from 'react-intersection-observer'
import dynamic from 'next/dynamic'
import { Row, Col } from 'antd'
import isEqual from 'lodash/isEqual'

import Gallery from 'components/Gallery'
import PriceBadge from 'components/PriceBadge'
import Rating from 'components/Rating'
import RequestedProviderNotice from 'components/RequestedProviderNotice'
import Badge from 'components/Badge'
import FormattedMessage from 'components/FormattedMessage'

import Badges from './Badges'
import StatusBadge from './StatusBadge'
import EventIdeaDetails from './EventIdeaDetails'
import EventRoomDetails from './EventRoomDetails'
import ProviderDetails from './ProviderDetails'

import { findPictureIndex } from 'helpers/providerPictures'
import { buildUrl } from 'helpers/params'

import { actionCreators as providerActions } from 'store/modules/Providers'
import { actionCreators as providerViewCounterActions } from 'store/modules/ProviderViewCounter'

import './index.less'

const ConsultantInformation = dynamic(
  () => import('components/ConsultantInformation'),
  { ssr: false },
)

const mapDispatchToProps = {
  sendProvidersListViewCountIncrement: providerViewCounterActions.sendProvidersListViewCountIncrement,
  setHoveredProviderId: providerActions.setHoveredProviderId,
}

const mapStateToProps = (state) => ({
  isPricesPerHourEnabled: state.headerData.experiments.showPricesPerHour,
})

class ProviderCard extends React.Component {
  state = {
    viewCounted: false,
  }

  shouldComponentUpdate (nextProps) {
    const {
      provider,
      actions,
      queryParams,
    } = this.props

    const {
      provider: nextProvider,
      actions: nextActions,
      queryParams: nextQueryParams,
    } = nextProps

    return provider && nextProvider && (
      provider.consultantInformation !== nextProvider.consultantInformation ||
      provider.isSubmitting !== nextProvider.isSubmitting ||
      provider.guests !== nextProvider.guests || // seating plan filter update for event rooms
      provider.requestStatus !== nextProvider.requestStatus ||
      provider.id !== nextProvider.id ||
      actions !== nextActions ||
      !isEqual(queryParams, nextQueryParams)
    )
  }

  renderProviderDetails () {
    const {
      provider,
      eventCategoryId,
    } = this.props

    const result = []

    if (provider.shortInfo && provider.type === 'EventSupplier') {
      result.push(
        <p className="provider-card__short-info" key={result.length}>
          {provider.shortInfo}
        </p>,
      )
    }

    switch (provider.type) {
      case 'EventIdea':
        result.push(<EventIdeaDetails provider={provider} key={result.length} />)
        break
      case 'EventRoom':
        result.push(<EventRoomDetails key={result.length} {...provider} />)
        break
      case 'EventLocation':
        result.push(<ProviderDetails provider={provider} key={result.length} eventCategoryId={eventCategoryId} />)
    }

    return result
  }

  renderGallery () {
    const {
      actions,
      eventCategoryId,
      isPricesPerHourEnabled,
      provider: {
        eventIdeaData,
        pictures,
        title,
        type,
        pricePerDay,
        pricePerHour,
        consultantInformation,
        requestStatus,
        hasActiveFrameContracts,
      },
    } = this.props

    let priceBadgeProps = null

    if (type === 'EventIdea' && eventIdeaData.price) {
      priceBadgeProps = {
        price: eventIdeaData.price,
        circa: true,
        per: 'person',
      }
    }

    if (type === 'EventRoom') {
      if (isPricesPerHourEnabled) {
        priceBadgeProps = {
          circa: true,
          price: pricePerHour || Math.round(pricePerDay / 8),
          per: 'hour',
        }
      } else if (pricePerDay) {
        priceBadgeProps = {
          circa: true,
          price: pricePerDay,
          per: 'day',
        }
      }
    }

    const galleryLinkContent = (
      <>
        <Gallery
          pictures={pictures}
          initialPictureIndex={findPictureIndex(pictures, eventCategoryId)}
          title={title}
          trackGalleryUsage={true}
          showSlider={true}
        />

        {priceBadgeProps && (
          <PriceBadge {...priceBadgeProps} />
        )}

        {(hasActiveFrameContracts || requestStatus) && (
          <div className="provider-card__status-container">
            {hasActiveFrameContracts && (
              <Badge
                type="frame-contract-color"
                size="x-small"
                className="provider-card__frame-contract-badge"
              >
                <FormattedMessage id="providerCard.frameContractBadge" defaultMessage="Frame Contract" />
              </Badge>
            )}
            {requestStatus && <StatusBadge requestStatus={requestStatus} className="provider-card__status-badge" />}
          </div>
        )}
      </>
    )

    const wrapperProps = {
      children: galleryLinkContent,
      className: classNames('provider-card__gallery-link', {
        'provider-card__gallery-link--has-price-badge': priceBadgeProps,
      }),
    }

    return this.optionalLinkWrapper(wrapperProps, !!consultantInformation || !!actions)
  }

  optionalLinkWrapper = (elementProps, linked) => {
    const { queryParams } = this.props

    let path = this.props.provider.path

    if (queryParams?.projectId) {
      path = buildUrl(path, queryParams)
    }

    return (
      linked
        ? <a {...elementProps} href={path} target="_blank" />
        : <div {...elementProps} />
    )
  }

  inViewHandler = (inView) => {
    const {
      provider,
      isInList,
    } = this.props

    if (
      inView &&
      provider.type === 'EventLocation' &&
      isInList
    ) {
      this.incrementViewCount()
    }
  }

  incrementViewCount () {
    const {
      provider,
      eventCategoryId,
      sendProvidersListViewCountIncrement,
    } = this.props

    sendProvidersListViewCountIncrement(provider.id, eventCategoryId)
  }

  renderCardContent () {
    const {
      actions,
      hideConsultantInformation,
      provider,
      provider: {
        type,
        title,
        consultantInformation,
        location,
        isHighlighted,
        isInstantBookable,
        isTopPerforming,
        hasRapidFeedback,
        hasHybridBadge,
        hasActiveFrameContracts,
      },
    } = this.props

    const showRating = !!provider.rating

    return (
      <>
        <Badges
          hasRapidFeedback={hasRapidFeedback}
          isHighlighted={isHighlighted}
          isTopPerforming={isTopPerforming}
          isInstantBookable={isInstantBookable}
          hasHybridBadge={hasHybridBadge}
          hasActiveFrameContracts={hasActiveFrameContracts}
        />

        {this.renderGallery()}

        <InView as="div" className="provider-card__content" triggerOnce={true} onChange={this.inViewHandler}>
          <h3 className="provider-card__headline">
            {title}
          </h3>

          <Row gutter={20} type="flex">
            <Col xs={24} md={showRating ? 12 : 24}>
              <div className="provider-card__location">{location}</div>
            </Col>

            {showRating && (
              <Col xs={24} md={12} className="provider-card__rating">
                <Rating rating={provider.rating} />
              </Col>
            )}
          </Row>

          {this.renderProviderDetails()}

          {type === 'EventLocation' && <RequestedProviderNotice provider={provider} />}

          {!hideConsultantInformation && (
            consultantInformation && (
              <ConsultantInformation provider={provider} />
            )
          )}

          {actions}
        </InView>
      </>
    )
  }

  render () {
    const {
      provider,
      actions,
      className,
      setHoveredProviderId,
      placeholder,
    } = this.props

    if (placeholder) {
      return <div className="provider-card provider-card--placeholder" />
    }

    const wrapperProps = {
      children: this.renderCardContent(),
      className: 'provider-card__card',
    }

    return (
      <div
        className={
          classNames(
            'provider-card',
            className,
            {
              'provider-card--highlighted': provider.isHighlighted,
            },
          )
        }
        onMouseEnter={() => setHoveredProviderId(provider.id)}
        onMouseLeave={() => setHoveredProviderId(null)}
      >
        {this.optionalLinkWrapper(wrapperProps, !provider.consultantInformation && !actions)}
      </div>
    )
  }
}

ProviderCard.propTypes = {
  actions: PropTypes.node,
  className: PropTypes.string,
  eventCategoryId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  hideConsultantInformation: PropTypes.bool,
  isPricesPerHourEnabled: PropTypes.bool,
  isInList: PropTypes.bool,
  openProviderProfileInNewTab: PropTypes.bool,
  placeholder: PropTypes.bool,
  queryParams: PropTypes.shape({
    projectId: PropTypes.number,
  }),
  sendProvidersListViewCountIncrement: PropTypes.func,
  setHoveredProviderId: PropTypes.func,
  viewProfileButton: PropTypes.bool,
  provider: PropTypes.shape({
    id: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number,
    ]),
    pricePerDay: PropTypes.string,
    pricePerHour: PropTypes.string,
    guests: PropTypes.number,
    type: PropTypes.string,
    path: PropTypes.string,
    pictures: PropTypes.array,
    title: PropTypes.string,
    location: PropTypes.string,
    shortInfo: PropTypes.string,
    consultantInformation: PropTypes.object,
    eventIdeaData: PropTypes.shape({
      price: PropTypes.string,
    }),
    hasActiveFrameContracts: PropTypes.bool,
    hasHybridBadge: PropTypes.bool,
    hasRapidFeedback: PropTypes.bool,
    isHighlighted: PropTypes.bool,
    isInstantBookable: PropTypes.bool,
    isTopPerforming: PropTypes.bool,
    isSubmitting: PropTypes.bool,
    rating: PropTypes.string,
    requestStatus: PropTypes.string,
  }),
}

export default connect(mapStateToProps, mapDispatchToProps)(ProviderCard)
