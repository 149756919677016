import React from 'react'
import PropTypes from 'prop-types'
import { injectIntl } from 'react-intl'

import AbstractProviderDetails from './AbstractProviderDetails'

class EventIdeaDetails extends React.PureComponent {
  render () {
    const {
      intl,
      provider,
    } = this.props

    let {
      eventCategories,
      eventIdeaData: {
        attendeesMin,
        attendeesMax,
        catering,
        roomCount,
        hotelRoomCount,
      },
    } = provider

    eventCategories = eventCategories || []

    const locationTypeInfo = eventCategories.map(eventCategory => eventCategory.headline).join(', ')
    const guests = [attendeesMin, attendeesMax].filter(Boolean).join('–')
    const cateringText = catering && intl.formatMessage({ id: `eventIdeaData.catering.${catering}` })

    return (
      <AbstractProviderDetails
        locationTypeInfo={locationTypeInfo}
        guests={guests}
        catering={cateringText}
        roomCount={roomCount}
        hotelRoomCount={hotelRoomCount}
      />
    )
  }
}

EventIdeaDetails.propTypes = {
  intl: PropTypes.object.isRequired,
  provider: PropTypes.shape({
    eventCategories: PropTypes.array,
    eventIdeaData: PropTypes.shape({
      catering: PropTypes.string,
      attendeesMin: PropTypes.number,
      attendeesMax: PropTypes.number,
      roomCount: PropTypes.number,
      hotelRoomCount: PropTypes.string,
    }),
  }),
}

export default injectIntl(EventIdeaDetails)
